.HerramientasContenedor {
	background-color: #d9d9d9;
	overflow: hidden;
	border-radius: 1.5rem;
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
	display: flex;
	overflow: hidden;
	margin: 3.8rem 1.6rem 3.2rem 1.6rem;
}

.HerramientasContenedor__inner {
	display: flex;
	gap: 2.4rem;
	white-space: nowrap;
	width: max-content;
}

.HerramientasContenedor__imagen {
	width: 4.8rem;
	height: 4.8rem;
}

@media (min-width: 1024px) {
	.HerramientasContenedor {
		background-color: rgba(0, 0, 0, 0.084);
		overflow: hidden;
		border-radius: 4rem;
		padding-top: 2rem;
		padding-bottom: 0;
		display: flex;
		overflow: hidden;
		margin-left: 16rem;
		margin-right: 16rem;
		margin-bottom: 2rem;
	}

	.HerramientasContenedor__inner {
		display: flex;
		gap: 9.2rem;
		white-space: nowrap;
		width: max-content;
	}

	.HerramientasContenedor__imagen {
		width: 18rem;
		height: 18rem;
	}
}
