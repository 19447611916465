.HerramientasContenedor {
  background-color: #d9d9d9;
  border-radius: 1.5rem;
  margin: 3.8rem 1.6rem 3.2rem;
  padding-top: .8rem;
  padding-bottom: .8rem;
  display: flex;
  overflow: hidden;
}

.HerramientasContenedor__inner {
  white-space: nowrap;
  gap: 2.4rem;
  width: max-content;
  display: flex;
}

.HerramientasContenedor__imagen {
  width: 4.8rem;
  height: 4.8rem;
}

@media (width >= 1024px) {
  .HerramientasContenedor {
    background-color: #00000015;
    border-radius: 4rem;
    margin-bottom: 2rem;
    margin-left: 16rem;
    margin-right: 16rem;
    padding-top: 2rem;
    padding-bottom: 0;
    display: flex;
    overflow: hidden;
  }

  .HerramientasContenedor__inner {
    white-space: nowrap;
    gap: 9.2rem;
    width: max-content;
    display: flex;
  }

  .HerramientasContenedor__imagen {
    width: 18rem;
    height: 18rem;
  }
}
/*# sourceMappingURL=index.0d577eb5.css.map */
